package com.neoutils.neoregex.core.database.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.TransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Long
import kotlin.String

public class TestCaseEntityQueries(
  driver: SqlDriver,
) : TransacterImpl(driver) {
  public fun <T : Any> getTestCase(uuid: String, mapper: (
    uuid: String,
    patternId: Long,
    title: String,
    text: String,
    testCase: String,
    createAt: Long,
  ) -> T): Query<T> = GetTestCaseQuery(uuid) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!
    )
  }

  public fun getTestCase(uuid: String): Query<TestCaseEntity> = getTestCase(uuid) { uuid_,
      patternId, title, text, testCase, createAt ->
    TestCaseEntity(
      uuid_,
      patternId,
      title,
      text,
      testCase,
      createAt
    )
  }

  public fun <T : Any> getTestCases(patternId: Long, mapper: (
    uuid: String,
    patternId: Long,
    title: String,
    text: String,
    testCase: String,
    createAt: Long,
  ) -> T): Query<T> = GetTestCasesQuery(patternId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!
    )
  }

  public fun getTestCases(patternId: Long): Query<TestCaseEntity> = getTestCases(patternId) { uuid,
      patternId_, title, text, testCase, createAt ->
    TestCaseEntity(
      uuid,
      patternId_,
      title,
      text,
      testCase,
      createAt
    )
  }

  public fun insertTestCase(
    uuid: String,
    patternId: Long,
    title: String,
    text: String,
    testCase: String,
    createAt: Long,
  ) {
    driver.execute(1_519_436_847, """
        |INSERT INTO TestCaseEntity(uuid, patternId, title, text, testCase, createAt)
        |VALUES (?, ?, ?, ?, ?, ?)
        """.trimMargin(), 6) {
          bindString(0, uuid)
          bindLong(1, patternId)
          bindString(2, title)
          bindString(3, text)
          bindString(4, testCase)
          bindLong(5, createAt)
        }
    notifyQueries(1_519_436_847) { emit ->
      emit("TestCaseEntity")
    }
  }

  public fun deleteTestCase(uuid: String) {
    driver.execute(-1_856_670_431, """
        |DELETE FROM TestCaseEntity
        |WHERE uuid = ?
        """.trimMargin(), 1) {
          bindString(0, uuid)
        }
    notifyQueries(-1_856_670_431) { emit ->
      emit("TestCaseEntity")
    }
  }

  public fun deleteTestCases(patternId: Long) {
    driver.execute(-1_722_208_398, """
        |DELETE FROM TestCaseEntity
        |WHERE patternId = ?
        """.trimMargin(), 1) {
          bindLong(0, patternId)
        }
    notifyQueries(-1_722_208_398) { emit ->
      emit("TestCaseEntity")
    }
  }

  public fun updateTestCase(
    title: String,
    text: String,
    testCase: String,
    uuid: String,
  ) {
    driver.execute(1_066_478_143, """
        |UPDATE TestCaseEntity
        |SET title = ?, text = ?, testCase = ?
        |WHERE uuid = ?
        """.trimMargin(), 4) {
          bindString(0, title)
          bindString(1, text)
          bindString(2, testCase)
          bindString(3, uuid)
        }
    notifyQueries(1_066_478_143) { emit ->
      emit("TestCaseEntity")
    }
  }

  private inner class GetTestCaseQuery<out T : Any>(
    public val uuid: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("TestCaseEntity", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("TestCaseEntity", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(371_122_532, """
    |SELECT TestCaseEntity.uuid, TestCaseEntity.patternId, TestCaseEntity.title, TestCaseEntity.text, TestCaseEntity.testCase, TestCaseEntity.createAt
    |FROM TestCaseEntity
    |WHERE uuid = ?
    """.trimMargin(), mapper, 1) {
      bindString(0, uuid)
    }

    override fun toString(): String = "TestCaseEntity.sq:getTestCase"
  }

  private inner class GetTestCasesQuery<out T : Any>(
    public val patternId: Long,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("TestCaseEntity", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("TestCaseEntity", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_380_103_281, """
    |SELECT TestCaseEntity.uuid, TestCaseEntity.patternId, TestCaseEntity.title, TestCaseEntity.text, TestCaseEntity.testCase, TestCaseEntity.createAt
    |FROM TestCaseEntity
    |WHERE patternId = ?
    |ORDER BY createAt ASC
    """.trimMargin(), mapper, 1) {
      bindLong(0, patternId)
    }

    override fun toString(): String = "TestCaseEntity.sq:getTestCases"
  }
}
