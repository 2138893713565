@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.neoutils.neoregex.core.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val fatal_error_copied_label: StringResource by 
      lazy { init_fatal_error_copied_label() }

  public val fatal_error_copy_btn: StringResource by 
      lazy { init_fatal_error_copy_btn() }

  public val fatal_error_message: StringResource by 
      lazy { init_fatal_error_message() }

  public val fatal_error_report_btn: StringResource by 
      lazy { init_fatal_error_report_btn() }

  public val fatal_error_report_tab: StringResource by 
      lazy { init_fatal_error_report_tab() }

  public val fatal_error_stack_trace_tab: StringResource by 
      lazy { init_fatal_error_stack_trace_tab() }

  public val fatal_error_subtitle: StringResource by 
      lazy { init_fatal_error_subtitle() }

  public val fatal_error_title: StringResource by 
      lazy { init_fatal_error_title() }

  public val insert_regex_hint: StringResource by 
      lazy { init_insert_regex_hint() }

  public val matcher_footer_insert_regex_hint: StringResource by 
      lazy { init_matcher_footer_insert_regex_hint() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("app_name", CommonMainString0.app_name)
  map.put("fatal_error_copied_label", CommonMainString0.fatal_error_copied_label)
  map.put("fatal_error_copy_btn", CommonMainString0.fatal_error_copy_btn)
  map.put("fatal_error_message", CommonMainString0.fatal_error_message)
  map.put("fatal_error_report_btn", CommonMainString0.fatal_error_report_btn)
  map.put("fatal_error_report_tab", CommonMainString0.fatal_error_report_tab)
  map.put("fatal_error_stack_trace_tab", CommonMainString0.fatal_error_stack_trace_tab)
  map.put("fatal_error_subtitle", CommonMainString0.fatal_error_subtitle)
  map.put("fatal_error_title", CommonMainString0.fatal_error_title)
  map.put("insert_regex_hint", CommonMainString0.insert_regex_hint)
  map.put("matcher_footer_insert_regex_hint", CommonMainString0.matcher_footer_insert_regex_hint)
}

public val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 101, 28),
    )
)

public val Res.string.fatal_error_copied_label: StringResource
  get() = CommonMainString0.fatal_error_copied_label

private fun init_fatal_error_copied_label(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_copied_label", "fatal_error_copied_label",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 130, 44),
    )
)

public val Res.string.fatal_error_copy_btn: StringResource
  get() = CommonMainString0.fatal_error_copy_btn

private fun init_fatal_error_copy_btn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_copy_btn", "fatal_error_copy_btn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 175, 44),
    )
)

public val Res.string.fatal_error_message: StringResource
  get() = CommonMainString0.fatal_error_message

private fun init_fatal_error_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_message", "fatal_error_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 220,
    111),
    )
)

public val Res.string.fatal_error_report_btn: StringResource
  get() = CommonMainString0.fatal_error_report_btn

private fun init_fatal_error_report_btn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_report_btn", "fatal_error_report_btn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 332, 46),
    )
)

public val Res.string.fatal_error_report_tab: StringResource
  get() = CommonMainString0.fatal_error_report_tab

private fun init_fatal_error_report_tab(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_report_tab", "fatal_error_report_tab",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 379, 46),
    )
)

public val Res.string.fatal_error_stack_trace_tab: StringResource
  get() = CommonMainString0.fatal_error_stack_trace_tab

private fun init_fatal_error_stack_trace_tab(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_stack_trace_tab", "fatal_error_stack_trace_tab",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 426, 51),
    )
)

public val Res.string.fatal_error_subtitle: StringResource
  get() = CommonMainString0.fatal_error_subtitle

private fun init_fatal_error_subtitle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_subtitle", "fatal_error_subtitle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 478, 64),
    )
)

public val Res.string.fatal_error_title: StringResource
  get() = CommonMainString0.fatal_error_title

private fun init_fatal_error_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_title", "fatal_error_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 543, 49),
    )
)

public val Res.string.insert_regex_hint: StringResource
  get() = CommonMainString0.insert_regex_hint

private fun init_insert_regex_hint(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:insert_regex_hint", "insert_regex_hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 593, 57),
    )
)

public val Res.string.matcher_footer_insert_regex_hint: StringResource
  get() = CommonMainString0.matcher_footer_insert_regex_hint

private fun init_matcher_footer_insert_regex_hint(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matcher_footer_insert_regex_hint", "matcher_footer_insert_regex_hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 651, 72),
    )
)
