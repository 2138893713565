package com.neoutils.neoregex.core.database.db

import app.cash.sqldelight.ExecutableQuery
import app.cash.sqldelight.Query
import app.cash.sqldelight.TransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Long
import kotlin.String

public class PatternEntityQueries(
  driver: SqlDriver,
) : TransacterImpl(driver) {
  public fun <T : Any> getPatternById(id: Long, mapper: (
    id: Long,
    title: String,
    text: String,
    createAt: Long,
  ) -> T): Query<T> = GetPatternByIdQuery(id) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!
    )
  }

  public fun getPatternById(id: Long): Query<PatternEntity> = getPatternById(id) { id_, title, text,
      createAt ->
    PatternEntity(
      id_,
      title,
      text,
      createAt
    )
  }

  public fun <T : Any> getAllPatterns(mapper: (
    id: Long,
    title: String,
    text: String,
    createAt: Long,
  ) -> T): Query<T> = Query(-640_172_498, arrayOf("PatternEntity"), driver, "PatternEntity.sq",
      "getAllPatterns", """
  |SELECT PatternEntity.id, PatternEntity.title, PatternEntity.text, PatternEntity.createAt
  |FROM PatternEntity
  |ORDER BY createAt DESC
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!
    )
  }

  public fun getAllPatterns(): Query<PatternEntity> = getAllPatterns { id, title, text, createAt ->
    PatternEntity(
      id,
      title,
      text,
      createAt
    )
  }

  public fun getLastInsertedId(): ExecutableQuery<Long> = Query(1_813_562_559, driver,
      "PatternEntity.sq", "getLastInsertedId", "SELECT last_insert_rowid()") { cursor ->
    cursor.getLong(0)!!
  }

  public fun insertPattern(
    title: String,
    text: String,
    createAt: Long,
  ) {
    driver.execute(1_006_908_119, """
        |INSERT INTO PatternEntity(title, text, createAt)
        |VALUES (?, ?, ?)
        """.trimMargin(), 3) {
          bindString(0, title)
          bindString(1, text)
          bindLong(2, createAt)
        }
    notifyQueries(1_006_908_119) { emit ->
      emit("PatternEntity")
    }
  }

  public fun deletePatternById(id: Long) {
    driver.execute(-641_986_281, """
        |DELETE FROM PatternEntity
        |WHERE id = ?
        """.trimMargin(), 1) {
          bindLong(0, id)
        }
    notifyQueries(-641_986_281) { emit ->
      emit("PatternEntity")
    }
  }

  public fun updatePattern(
    title: String,
    text: String,
    id: Long,
  ) {
    driver.execute(22_465_223, """
        |UPDATE PatternEntity
        |SET title = ?, text = ?
        |WHERE id = ?
        """.trimMargin(), 3) {
          bindString(0, title)
          bindString(1, text)
          bindLong(2, id)
        }
    notifyQueries(22_465_223) { emit ->
      emit("PatternEntity")
    }
  }

  private inner class GetPatternByIdQuery<out T : Any>(
    public val id: Long,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("PatternEntity", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("PatternEntity", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(565_116_428, """
    |SELECT PatternEntity.id, PatternEntity.title, PatternEntity.text, PatternEntity.createAt
    |FROM PatternEntity
    |WHERE id = ?
    """.trimMargin(), mapper, 1) {
      bindLong(0, id)
    }

    override fun toString(): String = "PatternEntity.sq:getPatternById"
  }
}
