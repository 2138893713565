@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.neoutils.neoregex.core.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val about_description_text: StringResource by 
      lazy { init_about_description_text() }

  public val about_libraries_btn: StringResource by 
      lazy { init_about_libraries_btn() }

  public val about_license_btn: StringResource by 
      lazy { init_about_license_btn() }

  public val about_runtime_text: StringResource by 
      lazy { init_about_runtime_text() }

  public val about_source_code_btn: StringResource by 
      lazy { init_about_source_code_btn() }

  public val about_version_text: StringResource by 
      lazy { init_about_version_text() }

  public val about_vm_text: StringResource by 
      lazy { init_about_vm_text() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val app_title: StringResource by 
      lazy { init_app_title() }

  public val fatal_error_copied_label: StringResource by 
      lazy { init_fatal_error_copied_label() }

  public val fatal_error_copy_btn: StringResource by 
      lazy { init_fatal_error_copy_btn() }

  public val fatal_error_message: StringResource by 
      lazy { init_fatal_error_message() }

  public val fatal_error_report_btn: StringResource by 
      lazy { init_fatal_error_report_btn() }

  public val fatal_error_report_tab: StringResource by 
      lazy { init_fatal_error_report_tab() }

  public val fatal_error_stack_trace_tab: StringResource by 
      lazy { init_fatal_error_stack_trace_tab() }

  public val fatal_error_subtitle: StringResource by 
      lazy { init_fatal_error_subtitle() }

  public val fatal_error_title: StringResource by 
      lazy { init_fatal_error_title() }

  public val matcher_footer_insert_regex_hint: StringResource by 
      lazy { init_matcher_footer_insert_regex_hint() }

  public val screen_about: StringResource by 
      lazy { init_screen_about() }

  public val screen_libraries: StringResource by 
      lazy { init_screen_libraries() }

  public val screen_matcher: StringResource by 
      lazy { init_screen_matcher() }

  public val screen_saved: StringResource by 
      lazy { init_screen_saved() }

  public val screen_validator: StringResource by 
      lazy { init_screen_validator() }

  public val test_case_match_any: StringResource by 
      lazy { init_test_case_match_any() }

  public val test_case_match_full: StringResource by 
      lazy { init_test_case_match_full() }

  public val test_case_match_none: StringResource by 
      lazy { init_test_case_match_none() }

  public val test_case_untitled: StringResource by 
      lazy { init_test_case_untitled() }

  public val web_warning_text: StringResource by 
      lazy { init_web_warning_text() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("about_description_text", CommonMainString0.about_description_text)
  map.put("about_libraries_btn", CommonMainString0.about_libraries_btn)
  map.put("about_license_btn", CommonMainString0.about_license_btn)
  map.put("about_runtime_text", CommonMainString0.about_runtime_text)
  map.put("about_source_code_btn", CommonMainString0.about_source_code_btn)
  map.put("about_version_text", CommonMainString0.about_version_text)
  map.put("about_vm_text", CommonMainString0.about_vm_text)
  map.put("app_name", CommonMainString0.app_name)
  map.put("app_title", CommonMainString0.app_title)
  map.put("fatal_error_copied_label", CommonMainString0.fatal_error_copied_label)
  map.put("fatal_error_copy_btn", CommonMainString0.fatal_error_copy_btn)
  map.put("fatal_error_message", CommonMainString0.fatal_error_message)
  map.put("fatal_error_report_btn", CommonMainString0.fatal_error_report_btn)
  map.put("fatal_error_report_tab", CommonMainString0.fatal_error_report_tab)
  map.put("fatal_error_stack_trace_tab", CommonMainString0.fatal_error_stack_trace_tab)
  map.put("fatal_error_subtitle", CommonMainString0.fatal_error_subtitle)
  map.put("fatal_error_title", CommonMainString0.fatal_error_title)
  map.put("matcher_footer_insert_regex_hint", CommonMainString0.matcher_footer_insert_regex_hint)
  map.put("screen_about", CommonMainString0.screen_about)
  map.put("screen_libraries", CommonMainString0.screen_libraries)
  map.put("screen_matcher", CommonMainString0.screen_matcher)
  map.put("screen_saved", CommonMainString0.screen_saved)
  map.put("screen_validator", CommonMainString0.screen_validator)
  map.put("test_case_match_any", CommonMainString0.test_case_match_any)
  map.put("test_case_match_full", CommonMainString0.test_case_match_full)
  map.put("test_case_match_none", CommonMainString0.test_case_match_none)
  map.put("test_case_untitled", CommonMainString0.test_case_untitled)
  map.put("web_warning_text", CommonMainString0.web_warning_text)
}

public val Res.string.about_description_text: StringResource
  get() = CommonMainString0.about_description_text

private fun init_about_description_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:about_description_text", "about_description_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 101,
    146),
    )
)

public val Res.string.about_libraries_btn: StringResource
  get() = CommonMainString0.about_libraries_btn

private fun init_about_libraries_btn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:about_libraries_btn", "about_libraries_btn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 248, 55),
    )
)

public val Res.string.about_license_btn: StringResource
  get() = CommonMainString0.about_license_btn

private fun init_about_license_btn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:about_license_btn", "about_license_btn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 304, 37),
    )
)

public val Res.string.about_runtime_text: StringResource
  get() = CommonMainString0.about_runtime_text

private fun init_about_runtime_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:about_runtime_text", "about_runtime_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 342, 54),
    )
)

public val Res.string.about_source_code_btn: StringResource
  get() = CommonMainString0.about_source_code_btn

private fun init_about_source_code_btn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:about_source_code_btn", "about_source_code_btn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 397, 45),
    )
)

public val Res.string.about_version_text: StringResource
  get() = CommonMainString0.about_version_text

private fun init_about_version_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:about_version_text", "about_version_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 443, 42),
    )
)

public val Res.string.about_vm_text: StringResource
  get() = CommonMainString0.about_vm_text

private fun init_about_vm_text(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:about_vm_text", "about_vm_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 486, 45),
    )
)

public val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 532, 28),
    )
)

public val Res.string.app_title: StringResource
  get() = CommonMainString0.app_title

private fun init_app_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_title", "app_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 561, 37),
    )
)

public val Res.string.fatal_error_copied_label: StringResource
  get() = CommonMainString0.fatal_error_copied_label

private fun init_fatal_error_copied_label(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_copied_label", "fatal_error_copied_label",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 599, 44),
    )
)

public val Res.string.fatal_error_copy_btn: StringResource
  get() = CommonMainString0.fatal_error_copy_btn

private fun init_fatal_error_copy_btn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_copy_btn", "fatal_error_copy_btn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 644, 44),
    )
)

public val Res.string.fatal_error_message: StringResource
  get() = CommonMainString0.fatal_error_message

private fun init_fatal_error_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_message", "fatal_error_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 689,
    111),
    )
)

public val Res.string.fatal_error_report_btn: StringResource
  get() = CommonMainString0.fatal_error_report_btn

private fun init_fatal_error_report_btn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_report_btn", "fatal_error_report_btn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 801, 46),
    )
)

public val Res.string.fatal_error_report_tab: StringResource
  get() = CommonMainString0.fatal_error_report_tab

private fun init_fatal_error_report_tab(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_report_tab", "fatal_error_report_tab",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 848, 46),
    )
)

public val Res.string.fatal_error_stack_trace_tab: StringResource
  get() = CommonMainString0.fatal_error_stack_trace_tab

private fun init_fatal_error_stack_trace_tab(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_stack_trace_tab", "fatal_error_stack_trace_tab",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 895, 51),
    )
)

public val Res.string.fatal_error_subtitle: StringResource
  get() = CommonMainString0.fatal_error_subtitle

private fun init_fatal_error_subtitle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_subtitle", "fatal_error_subtitle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 947, 64),
    )
)

public val Res.string.fatal_error_title: StringResource
  get() = CommonMainString0.fatal_error_title

private fun init_fatal_error_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_title", "fatal_error_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1012,
    49),
    )
)

public val Res.string.matcher_footer_insert_regex_hint: StringResource
  get() = CommonMainString0.matcher_footer_insert_regex_hint

private fun init_matcher_footer_insert_regex_hint(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matcher_footer_insert_regex_hint", "matcher_footer_insert_regex_hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1062,
    72),
    )
)

public val Res.string.screen_about: StringResource
  get() = CommonMainString0.screen_about

private fun init_screen_about(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:screen_about", "screen_about",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1135,
    28),
    )
)

public val Res.string.screen_libraries: StringResource
  get() = CommonMainString0.screen_libraries

private fun init_screen_libraries(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:screen_libraries", "screen_libraries",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1164,
    36),
    )
)

public val Res.string.screen_matcher: StringResource
  get() = CommonMainString0.screen_matcher

private fun init_screen_matcher(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:screen_matcher", "screen_matcher",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1201,
    34),
    )
)

public val Res.string.screen_saved: StringResource
  get() = CommonMainString0.screen_saved

private fun init_screen_saved(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:screen_saved", "screen_saved",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1236,
    28),
    )
)

public val Res.string.screen_validator: StringResource
  get() = CommonMainString0.screen_validator

private fun init_screen_validator(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:screen_validator", "screen_validator",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1265,
    36),
    )
)

public val Res.string.test_case_match_any: StringResource
  get() = CommonMainString0.test_case_match_any

private fun init_test_case_match_any(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:test_case_match_any", "test_case_match_any",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1302,
    39),
    )
)

public val Res.string.test_case_match_full: StringResource
  get() = CommonMainString0.test_case_match_full

private fun init_test_case_match_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:test_case_match_full", "test_case_match_full",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1342,
    44),
    )
)

public val Res.string.test_case_match_none: StringResource
  get() = CommonMainString0.test_case_match_none

private fun init_test_case_match_none(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:test_case_match_none", "test_case_match_none",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1387,
    44),
    )
)

public val Res.string.test_case_untitled: StringResource
  get() = CommonMainString0.test_case_untitled

private fun init_test_case_untitled(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:test_case_untitled", "test_case_untitled",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1432,
    38),
    )
)

public val Res.string.web_warning_text: StringResource
  get() = CommonMainString0.web_warning_text

private fun init_web_warning_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:web_warning_text", "web_warning_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1471,
    72),
    )
)
