package com.neoutils.neoregex.core.database.db

import kotlin.Long
import kotlin.String

public data class TestCaseEntity(
  public val uuid: String,
  public val patternId: Long,
  public val title: String,
  public val text: String,
  public val testCase: String,
  public val createAt: Long,
)
