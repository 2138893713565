@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.neoutils.neoregex.core.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.PluralStringResource

private object CommonMainPlurals0 {
  public val match_result_infos: PluralStringResource by 
      lazy { init_match_result_infos() }
}

@InternalResourceApi
internal fun _collectCommonMainPlurals0Resources(map: MutableMap<String, PluralStringResource>) {
  map.put("match_result_infos", CommonMainPlurals0.match_result_infos)
}

public val Res.plurals.match_result_infos: PluralStringResource
  get() = CommonMainPlurals0.match_result_infos

private fun init_match_result_infos(): PluralStringResource =
    org.jetbrains.compose.resources.PluralStringResource(
  "plurals:match_result_infos", "match_result_infos",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 10, 90),
    )
)
