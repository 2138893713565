package com.neoutils.neoregex.core.database.db.database

import app.cash.sqldelight.TransacterImpl
import app.cash.sqldelight.db.AfterVersion
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import com.neoutils.neoregex.core.database.db.PatternDatabase
import com.neoutils.neoregex.core.database.db.PatternEntityQueries
import com.neoutils.neoregex.core.database.db.TestCaseEntityQueries
import kotlin.Long
import kotlin.Unit
import kotlin.reflect.KClass

internal val KClass<PatternDatabase>.schema: SqlSchema<QueryResult.Value<Unit>>
  get() = PatternDatabaseImpl.Schema

internal fun KClass<PatternDatabase>.newInstance(driver: SqlDriver): PatternDatabase =
    PatternDatabaseImpl(driver)

private class PatternDatabaseImpl(
  driver: SqlDriver,
) : TransacterImpl(driver), PatternDatabase {
  override val patternEntityQueries: PatternEntityQueries = PatternEntityQueries(driver)

  override val testCaseEntityQueries: TestCaseEntityQueries = TestCaseEntityQueries(driver)

  public object Schema : SqlSchema<QueryResult.Value<Unit>> {
    override val version: Long
      get() = 1

    override fun create(driver: SqlDriver): QueryResult.Value<Unit> {
      driver.execute(null, """
          |CREATE TABLE PatternEntity (
          |    id INTEGER PRIMARY KEY AUTOINCREMENT,
          |    title TEXT NOT NULL,
          |    text TEXT NOT NULL,
          |    createAt INTEGER NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE TestCaseEntity (
          |    uuid TEXT NOT NULL PRIMARY KEY,
          |    patternId INTEGER NOT NULL,
          |    title TEXT NOT NULL,
          |    text TEXT NOT NULL,
          |    testCase TEXT NOT NULL,
          |    createAt INTEGER NOT NULL,
          |    FOREIGN KEY (patternId) REFERENCES PatternEntity(id)
          |)
          """.trimMargin(), 0)
      return QueryResult.Unit
    }

    override fun migrate(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
      vararg callbacks: AfterVersion,
    ): QueryResult.Value<Unit> = QueryResult.Unit
  }
}
