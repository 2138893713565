package com.neoutils.neoregex

import kotlin.Int
import kotlin.OptIn
import kotlin.String
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@JsExport
@OptIn(ExperimentalJsExport::class)
public object NeoConfig {
  public val version: String = "3.0.0-dev"

  public val code: Int = 300
}
