package com.neoutils.neoregex.core.database.db

import kotlin.Long
import kotlin.String

public data class PatternEntity(
  public val id: Long,
  public val title: String,
  public val text: String,
  public val createAt: Long,
)
