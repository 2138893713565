package com.neoutils.neoregex.core.database.db

import app.cash.sqldelight.Transacter
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import com.neoutils.neoregex.core.database.db.database.newInstance
import com.neoutils.neoregex.core.database.db.database.schema
import kotlin.Unit

public interface PatternDatabase : Transacter {
  public val patternEntityQueries: PatternEntityQueries

  public val testCaseEntityQueries: TestCaseEntityQueries

  public companion object {
    public val Schema: SqlSchema<QueryResult.Value<Unit>>
      get() = PatternDatabase::class.schema

    public operator fun invoke(driver: SqlDriver): PatternDatabase =
        PatternDatabase::class.newInstance(driver)
  }
}
