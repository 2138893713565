@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.neoutils.neoregex.core.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainFont0 {
  public val jetbrains_mono: FontResource by 
      lazy { init_jetbrains_mono() }

  public val roboto_mono: FontResource by 
      lazy { init_roboto_mono() }
}

@InternalResourceApi
internal fun _collectCommonMainFont0Resources(map: MutableMap<String, FontResource>) {
  map.put("jetbrains_mono", CommonMainFont0.jetbrains_mono)
  map.put("roboto_mono", CommonMainFont0.roboto_mono)
}

public val Res.font.jetbrains_mono: FontResource
  get() = CommonMainFont0.jetbrains_mono

private fun init_jetbrains_mono(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:jetbrains_mono",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/font/jetbrains_mono.ttf", -1, -1),
    )
)

public val Res.font.roboto_mono: FontResource
  get() = CommonMainFont0.roboto_mono

private fun init_roboto_mono(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_mono",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/font/roboto_mono.ttf", -1, -1),
    )
)
